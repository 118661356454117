<template>
  <div class="edge_processing_block">
    <div class="edge_processing_block_top">
      <p>{{ processingOption.name }}</p>
    </div>
    <div class="edge_processing_block_center">
      <v-selecting-sides
        :borders="processingOption.borders"
        :optionIndex="optionIndex"
        :characteristicIndex="characteristicIndex"
        @selectSideBtn="selectSideBtn"
        @selectAllSidesBtn="selectAllSidesBtn"
      />
    </div>
    <div class="edge_processing_block_bottom">
      <v-processing-btn
        v-show="processingOption.values"
        v-for="(btn, index) in processingOption.values"
        :key="index"
        :pro-btn="btn"
        :optionIndex="optionIndex"
        :characteristicIndex="characteristicIndex"
        :idProBtn="index"
        :computedCurrentSidesBtn="computedCurrentSidesBtn"
        @selectProBtn="selectProBtn"
      >
      </v-processing-btn>
    </div>
  </div>
</template>

<script>
import VSelectingSides from "@/components/CostCalculation/v-selecting-sides";
import VProcessingBtn from "@/components/CostCalculation/v-processing-btn";

export default {
  name: "v-processing-option",
  components: { VProcessingBtn, VSelectingSides },
  props: {
    processingOption: {
      type: Object,
      default() {
        return {};
      },
    },
    optionIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      currentValuesProBtn: {
        idProBtn: 0,
        valueProBtn: "",
      },
    };
  },
  computed: {
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    computedCurrentSidesBtn() {
      return this.CHARACTERISTICS[
        this.characteristicIndex
      ].sub_characteristics.filter((char) => {
        return char.optionIndex === this.optionIndex;
      });
    },
  },
  methods: {
    selectSideBtn(infoSelectSideBtn) {
      infoSelectSideBtn["idProBtn"] = this.currentValuesProBtn.idProBtn;
      infoSelectSideBtn["valueProBtn"] = this.currentValuesProBtn.valueProBtn;
      this.$emit("selectSideBtn", infoSelectSideBtn);
    },
    selectAllSidesBtn(infoSidesBtn) {
      infoSidesBtn.forEach((button) => {
        button["idProBtn"] = this.currentValuesProBtn.idProBtn;
        button["valueProBtn"] = this.currentValuesProBtn.valueProBtn;
      });
      this.$emit("selectAllSidesBtn", infoSidesBtn);
    },
    selectProBtn(infoSelectProBtn) {
      if (this.computedCurrentSidesBtn.length > 0) {
        this.currentValuesProBtn.valueProBtn = infoSelectProBtn.valueProBtn;
        this.currentValuesProBtn.idProBtn = infoSelectProBtn.idProBtn;
        let calcChar = [];
        this.computedCurrentSidesBtn.forEach((item) => {
          item.value = infoSelectProBtn.valueProBtn;
          item.idProBtn = infoSelectProBtn.idProBtn;
          calcChar.push(item);
        });
        this.$emit("selectProBtn", calcChar);
      } else {
        this.$message({
          message: "Не выбран край для обработки.",
          type: "error",
          offset: 90,
        });
      }
    },
  },
  mounted() {
    this.currentValuesProBtn.valueProBtn =
      this.processingOption.values[0].value;
  },
};
</script>

<style scoped></style>
