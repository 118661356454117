<template>
  <div class="selection_of_grommets">
    <v-grommets-sizes
      :option="value_options[0]"
      :key="0"
      :characteristicIndex="characteristicIndex"
      @selectGrommBtn="selectGrommBtn"
    />
    <v-grommets-sides
      :option="value_options[1]"
      :key="1"
      :characteristicIndex="characteristicIndex"
      @selectGrommSideBtn="selectGrommSideBtn"
      @selectGrommAllSidesBtn="selectGrommAllSidesBtn"
    />
    <v-grommets-steps
      :option="value_options[2]"
      :key="2"
      :characteristicIndex="characteristicIndex"
      @selectGrommBtn="selectGrommBtn"
    />
  </div>
</template>

<script>
import VGrommetsSizes from "@/components/CostCalculation/v-grommets-sizes";
import VGrommetsSteps from "@/components/CostCalculation/v-grommets-steps";
import VGrommetsSides from "@/components/CostCalculation/v-grommets-sides";
// import _ from "lodash";
export default {
  name: "v-selection-grommets",
  components: { VGrommetsSides, VGrommetsSteps, VGrommetsSizes },
  props: {
    value_options: {
      type: Array,
      default() {
        return [];
      },
    },
    idCharacteristic: {
      type: Number,
      default() {
        return 0;
      },
    },
    calcGrommetsCharacteristics: {
      type: Array,
      default() {
        return [];
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      calcCharacteristics: [],
    };
  },
  computed: {},
  methods: {
    typeExists(name) {
      return this.value_options.some(function (el) {
        return el.type === name;
      });
    },
    sortValueOptions(name) {
      return this.value_options.forEach(function (el) {
        if (el.type === name) {
          return el;
        }
      });
    },
    selectGrommBtn(infoSelectGrommBtn) {
      // let calcCharacteristics = _.cloneDeep(this.calcCharacteristics);
      this.calcCharacteristics[infoSelectGrommBtn.optionIndex].values =
        infoSelectGrommBtn.values;
      this.$emit(
        "setCalcCharacteristics",
        this.characteristicIndex,
        this.calcCharacteristics,
        this.idCharacteristic
      );
    },
    selectGrommSideBtn(infoSelectGrommSideBtn) {
      // let calcCharacteristics = _.cloneDeep(this.calcCharacteristics);
      this.calcCharacteristics[infoSelectGrommSideBtn.optionIndex].values[
        infoSelectGrommSideBtn.idSideBtn
      ].selected = infoSelectGrommSideBtn.selected;
      this.calcCharacteristics[infoSelectGrommSideBtn.optionIndex].values[
        infoSelectGrommSideBtn.idSideBtn
      ].id = infoSelectGrommSideBtn.id;
      this.$emit(
        "setCalcCharacteristics",
        this.characteristicIndex,
        this.calcCharacteristics,
        this.idCharacteristic
      );
    },
    selectGrommAllSidesBtn(selected) {
      // let calcCharacteristics = _.cloneDeep(this.calcCharacteristics);
      this.calcCharacteristics[1].values.forEach((char) => {
        char.selected = selected;
      });
      this.$emit(
        "setCalcCharacteristics",
        this.characteristicIndex,
        this.calcCharacteristics,
        this.idCharacteristic
      );
    },
  },
  mounted() {
    this.calcCharacteristics = this.calcGrommetsCharacteristics;
  },
  watch: {
    calcGrommetsCharacteristics: function (value) {
      this.calcCharacteristics = value;
    },
  },
};
</script>

<style scoped></style>
