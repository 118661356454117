<template>
  <component :is="new_order_component">
    <router-view />
  </component>
</template>

<script>
import ListProducts from "@/components/NewOrder/NewOrderListProducts";
import CostCalculation from "@/components/NewOrder/NewOrderCostCalculation";
export default {
  name: "NewOrder",
  components: { ListProducts, CostCalculation },
  computed: {
    new_order_component() {
      return this.$route.meta.new_order_component;
    },
  },
};
</script>

<style scoped></style>
