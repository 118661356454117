<template>
  <div
    class="btn-160"
    :class="{
      iconActive: definition !== '',
      activeBtn: isActiveBtn(index, option_data),
      btn_not_active: isExeptions,
    }"
    @click="
      selectOption(option_data.value, option_data.id, index, id_characteristic)
    "
  >
    {{ option_data.name }}
    <div class="icon-info" v-if="definition !== ''">
      <span class="tip">
        {{ definition }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-btn",
  props: {
    option_data: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    id_characteristic: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      characteristic: {},
      definition: "",
      isActive: false,
    };
  },
  methods: {
    selectOption(value, id_value, index, id_characteristic) {
      this.$emit("selectOption", value, id_value, index, id_characteristic);
    },
    isActiveBtn(index, option_data) {
      return this.CHARACTERISTICS[index].value === option_data.value;
    },
  },
  computed: {
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    EXEPTIONS() {
      return this.$store.getters["CostCalculation/EXEPTIONS"];
    },
    isExeptions() {
      let is = false;
      this.EXEPTIONS.forEach((item) => {
        if (
          this.option_data.id in item &&
          this.listOfSelectedOptions.indexOf(item[this.option_data.id]) !== -1
        ) {
          is = true;
        }
      });
      return is;
    },
    listOfSelectedOptions() {
      let array = [];
      let characteristics =
        this.$store.getters["CostCalculation/CHARACTERISTICS"];
      characteristics.forEach((item) => {
        array.push(item.id_value);
      });
      return array;
    },
  },
  mounted() {
    this.definition = this.option_data.definition;
  },
};
</script>

<style lang="sass"></style>
