<template>
  <div class="v-btn-quantity">
    <div
      v-for="(option, j) in computedListOption"
      :key="option.id"
      class="btn-160"
      :class="{
        iconActive: option.definition !== '',
        activeEl: activeEl === String(j),
      }"
      @click="multiplyActions(option, index, id_characteristic, j)"
    >
      {{ option.name }}
      <div class="icon-info" v-if="option.definition !== ''">
        <span class="tip">
          {{ option.definition }}
        </span>
      </div>
    </div>
    <div class="group-quantity">
      <div
        v-for="(myEdition, i) in computedMyEdition"
        :key="myEdition.id"
        @click="updateActiveEl('quantity')"
        class="div-quantity"
      >
        <span v-if="i === 1">x</span>
        <v-quantity
          :myEdition="myEdition"
          :maskMin="myEdition.maskMin"
          :maskMax="myEdition.maskMax"
          :maskStep="myEdition.maskStep"
          :maskLength="myEdition.maskLength"
          :index="index"
          :id_characteristic="id_characteristic"
          :sel="selectedInt"
          :size="size"
          :i="i"
          @selectedEdition="selectedMyQuantity"
          @selectedSize="selectedSize"
          :activeElement="activeEl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vQuantity from "@/components/CostCalculation/v-quantity";

export default {
  name: "v-btn-quantity",
  components: {
    vQuantity,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    id_characteristic: {
      type: Number,
      default() {
        return 0;
      },
    },
    size: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      characteristic: {},
      myQuantity: "",
      activeEl: "0",
      sizeValue: "",
    };
  },
  methods: {
    selectOption(option, index, id_characteristic) {
      this.myQuantity = option.value;
      this.$emit(
        "selectedEdition",
        option.value,
        option.id,
        index,
        id_characteristic
      );
    },
    selectedMyQuantity(myEdition, id, index, id_characteristic) {
      this.$emit("selectedEdition", myEdition, id, index, id_characteristic);
    },
    selectedSize(size_value, id, index, id_characteristic, i) {
      let arraySize = this.sizeValue.split("*", 2);
      arraySize[i] = size_value;
      this.sizeValue = arraySize.join("*");
      this.$emit(
        "selectedEdition",
        this.sizeValue,
        id,
        index,
        id_characteristic
      );
    },
    updateActiveEl(element) {
      this.activeEl = String(element);
    },
    multiplyActions(option, index, id_characteristic, element) {
      this.selectOption(option, index, id_characteristic);
      this.updateActiveEl(element);
    },
  },
  computed: {
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    computedListOption: function () {
      return this.options.filter(function (option) {
        if (!option.type_of_execution) {
          return option;
        }
      });
    },
    computedMyEdition: function () {
      return this.options.filter(function (option) {
        if (option.type_of_execution === 12) {
          if (option.mask) {
            let arrayMask = option.mask.split(",", 3);
            option.maskMin = arrayMask[0];
            option.maskMax = arrayMask[1];
            option.maskStep = arrayMask[2];
            option.maskLength = arrayMask[1].length;
          }
          return option;
        }
      });
    },
    selectedInt: function () {
      if (this.activeEl === "0" && this.computedListOption.length === 1)
        return "";
      return String(this.myQuantity);
    },
  },
  watch: {
    myQuantity: function (value) {
      this.sizeValue = value;
    },
  },
  mounted() {
    this.myQuantity = this.computedListOption[0].value;
  },
};
</script>
<style lang="sass"></style>
