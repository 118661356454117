<template>
  <div class="v-main">
    <section v-show="TEMPLATE" class="main_block main_app">
      <div class="main_block_left">
        <v-list-types @sendCharacteristics="sendCharacteristics"></v-list-types>
      </div>
      <div
        v-for="type in oneType"
        :key="type.id"
        class="main_block_center_left"
      >
        <div class="container_model">
          <section
            v-if="type.path_to_model"
            id="modelViewerContainer"
            v-load-model="type.path_to_model"
          ></section>
          <div v-else>3D модели нет</div>
        </div>
        <div v-show="type.path_to_picture.length > 0" class="container_slider">
          <div class="owl-carousel" id="slider">
            <a
              v-for="(path, j) in type.path_to_picture"
              :key="j"
              :href="path"
              data-fancybox="gallery"
            >
              <img
                :src="path"
                class="slide-image-calc"
                v-callback="j === type.path_to_picture.length - 1"
                alt=""
              />
            </a>
          </div>
        </div>
        <div v-if="type.path_to_template.length > 0" class="container_link">
          <a :href="type.path_to_template" download>
            <div class="container_link_block">
              <div class="icon_pdf"></div>
              <div class="container_link_block_title">
                Скачать шаблон в формате
                <span class="color_accent"> *.PDF </span>
              </div>
            </div>
          </a>
        </div>
        <!--        <div class="container_link">-->
        <!--          <a-->
        <!--            href="https://b2b.gprint.ru/goodprint/files/templates/11/kTmpt_11_000.pdf"-->
        <!--            download-->
        <!--          >-->
        <!--            <div class="container_link_block">-->
        <!--              <div class="icon_pdf_commercial"></div>-->
        <!--              <div class="container_link_block_title">-->
        <!--                Коммерческое предложение-->
        <!--                <span class="color_commercial">-->
        <!--                  *.PDF-->
        <!--                </span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </a>-->
        <!--        </div>-->
      </div>
      <v-list :characteristics-props="characteristics"></v-list>
    </section>
  </div>
</template>

<script>
import vList from "./v-list";
import vListTypes from "./v-list-types";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/owl.carousel.min";
import "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.css";
export default {
  name: "v-main",
  components: {
    vList,
    vListTypes,
  },
  data() {
    return {
      oneType: {},
      characteristics: [],
    };
  },
  computed: {
    TEMPLATE() {
      return this.$store.getters["CostCalculation/TEMPLATE"];
    },
    ONE_TYPE() {
      return this.$store.getters["CostCalculation/ONE_TYPE"];
    },
    TYPE() {
      return this.$store.getters["CostCalculation/TYPE"];
    },
  },
  watch: {
    ONE_TYPE: function (value) {
      this.oneType = value;
    },
    TEMPLATE: function (template) {
      const $ = window.$;
      $(document).ready(function () {
        $("#template_caption").text(template.caption);
        $("#template_name").text(template.name);
        if (template.path_to_picture !== "") {
          $("#template_img").attr("src", template.path_to_picture);
        }
      });
    },
  },
  methods: {
    // openPreloader() {
    //   this.prelo = this.$loading({
    //     lock: true,
    //     background: "#ffffff",
    //     target: ".main_app",
    //     spinner: "el-loading-spinner",
    //   });
    // },
    // closePreloader() {
    //   this.prelo.close();
    // },
    sendCharacteristics(characteristics) {
      this.characteristics = characteristics;
    },
  },
  mounted() {
    // this.openPreloader();
    // setInterval(this.prelo.close(), 2000);

    this.TYPE.forEach((item, i) => {
      this.characteristics[i] = {
        id_characteristic: item["id"],
        sub_characteristics: [],
        value: item.value_options[0]["value"],
        id_value: item.value_options[0]["id"],
      };
    });
  },
  directives: {
    callback: {
      inserted: function (el, binding) {
        const $ = window.$;
        if (binding.value) {
          $("#slider").owlCarousel({
            center: true,
            items: 1,
            loop: true,
            margin: 4,
            stagePadding: 0,
            autoWidth: true,
            dots: false,
            nav: true,
            navText: ['<i class="icon-prev"></i>', '<i class="icon-next"></i>'],
          });

          $("[data-fancybox]").fancybox({
            loop: true,
            infobar: false,
            animationEffect: "zoom",
            animationDuration: 600,
            transitionEffect: "circular",
            transitionDuration: 400,
            // Какие кнопки на панели управления
            slideShow: false,
            fullScreen: true,
            thumbs: false,
            closeBtn: true,
            scrolling: "yes",
          });
        }
      },
    },
    loadModel: {
      inserted: function (el, binding) {
        let configObject = {
          backgroundColor: "#FBFBFC",
          backgroundGradient: false,
          zoomEnabled: true,
          loadingScreenMessage: "",
          castShadows: true,
          shadowBlur: 10,
        };

        // eslint-disable-next-line no-undef
        let modelViewer = new ModelViewer(configObject);

        modelViewer.initialize(binding.value, () => {
          // console.log("Model loaded");
        });
      },
    },
  },
};
</script>

<style scoped></style>
