<template>
  <div class="edge_processing">
    <v-processing-option
      v-for="(option, index) in value_options"
      :key="index"
      :processing-option="option"
      :optionIndex="index"
      :characteristicIndex="characteristicIndex"
      @selectSideBtn="setCalcCharacteristics"
      @selectAllSidesBtn="setCalcAllSidesBtn"
      @selectProBtn="setProBtn"
    >
    </v-processing-option>
    <!--    <v-debug-->
    <!--      name="calcProcessingCharacteristics"-->
    <!--      :variable="this.calcProcessingCharacteristics"-->
    <!--      height="100vh"-->
    <!--      moving="Y"-->
    <!--    />-->
  </div>
</template>

<script>
import VProcessingOption from "@/components/CostCalculation/v-processing-option";
// import _ from "lodash";
// import VDebug from "@/components/debug/v-debug";
export default {
  name: "v-edge-processing",
  components: { VProcessingOption },
  props: {
    value_options: {
      type: Array,
      default() {
        return [];
      },
    },
    idCharacteristic: {
      type: Number,
      default() {
        return 0;
      },
    },
    calcProcessingCharacteristics: {
      type: Array,
      default() {
        return [];
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      calcCharacteristics: [],
    };
  },
  methods: {
    setCalcCharacteristics(infoSelectSideBtn) {
      // let calcCharacteristics = _.cloneDeep(this.calcCharacteristics);
      this.calcCharacteristics[infoSelectSideBtn.idSideBtn].id_characteristic =
        infoSelectSideBtn.bid;
      this.calcCharacteristics[infoSelectSideBtn.idSideBtn].optionIndex =
        infoSelectSideBtn.optionIndex;
      this.calcCharacteristics[infoSelectSideBtn.idSideBtn].idProBtn =
        infoSelectSideBtn.idProBtn;
      this.calcCharacteristics[infoSelectSideBtn.idSideBtn].value =
        infoSelectSideBtn.valueProBtn;
      this.$emit(
        "setCalcCharacteristics",
        this.characteristicIndex,
        this.calcCharacteristics,
        this.idCharacteristic
      );
    },
    setCalcAllSidesBtn(infoSidesBtn) {
      // let calcCharacteristics = _.cloneDeep(this.calcCharacteristics);
      infoSidesBtn.forEach((button) => {
        this.calcCharacteristics[button.idSideBtn].value = button.valueProBtn;
        this.calcCharacteristics[button.idSideBtn].idProBtn = button.idProBtn;
        this.calcCharacteristics[button.idSideBtn].idSideBtn = button.idSideBtn;
        this.calcCharacteristics[button.idSideBtn].optionIndex =
          button.optionIndex;
        this.calcCharacteristics[button.idSideBtn].id_characteristic =
          button.bid;
      });
      this.$emit(
        "setCalcCharacteristics",
        this.characteristicIndex,
        this.calcCharacteristics,
        this.idCharacteristic
      );
    },
    setProBtn(calcChar) {
      if (calcChar.length > 0) {
        // let calcCharacteristics = _.cloneDeep(this.calcCharacteristics);
        calcChar.forEach((item) => {
          this.calcCharacteristics[item.idSideBtn].value = item.value;
          this.calcCharacteristics[item.idSideBtn].idProBtn = item.idProBtn;
        });
        this.$emit(
          "setCalcCharacteristics",
          this.characteristicIndex,
          this.calcCharacteristics,
          this.idCharacteristic
        );
      }
    },
  },
  mounted() {
    this.calcCharacteristics = this.calcProcessingCharacteristics;
  },
  watch: {
    calcProcessingCharacteristics: function (value) {
      this.calcCharacteristics = value;
    },
  },
};
</script>

<style scoped></style>
