<template>
  <div
    :class="[
      cl,
      btn_blocked ? 'btn_blocked' : '',
      inactiveBlock || activeCorners ? 'btn_blocked' : '',
      isFixed ? isFixedCurrentCssClass : '',
      isFixedCurrent ? isFixedCurrentCssClass : '',
      selected && !inactiveBlock ? 'gromm_btn_selected' : '',
    ]"
    @click="selectGrommSideBtn"
  ></div>
</template>

<script>
export default {
  name: "v-gromm-side-btn",
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    cl: {
      type: String,
      default() {
        return "";
      },
    },
    idSideBtn: {
      type: Number,
      default() {
        return 0;
      },
    },
    id: {
      type: Number,
      default() {
        return 0;
      },
    },
    optionIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    btn_blocked: {
      type: Boolean,
      default() {
        return true;
      },
    },
    fixed: {
      type: Boolean,
      default() {
        return false;
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    isActiveBtn() {
      if (this.idSideBtn === 4) {
        let currentSidesInOption = this.CHARACTERISTICS[
          this.characteristicIndex
        ].sub_characteristics[1].values.filter((char) => {
          return char.selected === true;
        });
        return currentSidesInOption.length === 4;
      } else if (
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[1]
          .values[this.idSideBtn].selected === true
      ) {
        return true;
      }
      return false;
    },
    inactiveBlock() {
      return (
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[0]
          .values.value === "0"
      );
    },
    activeCorners() {
      return (
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[2]
          .values.value === "-1"
      );
    },
    isFixed() {
      return (
        this.fixed === true &&
        this.btn_blocked === false &&
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[0]
          .values.value !== "0"
      );
    },
    isFixedCurrent() {
      let currentSidesInOption = this.CHARACTERISTICS[
        this.characteristicIndex
      ].sub_characteristics[1].values.filter((char) => {
        return char.selected === true;
      });
      return (
        ((currentSidesInOption.length === 1 &&
          this.idSideBtn === currentSidesInOption[0].idSideBtn) ||
          (currentSidesInOption.length === 4 && this.idSideBtn === 4)) &&
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[0]
          .values.value !== "0"
      );
    },
    isFixedCurrentCssClass() {
      if (
        this.idSideBtn === 4 ||
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[2]
          .values.value === "-1"
      )
        return "btn_blocked";
      else return "btn_fixed";
    },
    selected() {
      if (this.idSideBtn === 4) {
        return false;
        // let currentSidesInOption = this.CHARACTERISTICS[
        //   this.characteristicIndex
        // ].sub_characteristics[1].values.filter(char => {
        //   return char.selected === true;
        // });
        // return currentSidesInOption.length === 4;
      }
      return this.CHARACTERISTICS[this.characteristicIndex]
        .sub_characteristics[1].values[this.idSideBtn].selected;
    },
  },
  methods: {
    selectGrommSideBtn() {
      if (this.idSideBtn === 4) {
        this.$emit("selectGrommAllSidesBtn", !this.selected);
      } else {
        let infoSelectGrommSideBtn = {
          optionIndex: this.optionIndex,
          idSideBtn: this.idSideBtn,
          id: this.id,
          selected: !this.selected,
        };
        this.$emit("selectGrommSideBtn", infoSelectGrommSideBtn);
      }
    },
  },
};
</script>

<style scoped></style>
