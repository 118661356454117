<template>
  <div v-show="show_component" class="v-list main_block_double_right">
    <div class="main_block_center_right">
      <div
        :class="[
          item.type_of_execution === 4 || item.type_of_execution === 5
            ? 'characteristic_block'
            : 'online-order-center-block',
        ]"
        v-for="(item, index) in TYPE"
        :key="item.id"
        v-show="!item.ProdCount"
      >
        <div
          style="font-size: 15px"
          v-if="item.type_of_execution === 4 || item.type_of_execution === 5"
        >
          {{ item.name }}
        </div>
        <v-edge-processing
          v-if="item.type_of_execution === 4"
          :value_options="item.value_options"
          :idCharacteristic="item.id"
          :characteristicIndex="index"
          :calcProcessingCharacteristics="calcProcessingCharacteristics"
          @setCalcCharacteristics="setCalcCharacteristics"
        />
        <v-selection-grommets
          v-if="item.type_of_execution === 5 && isTemplateGrommetsCorrect"
          :value_options="item.value_options"
          :idCharacteristic="item.id"
          :characteristicIndex="index"
          :calcGrommetsCharacteristics="calcGrommetsCharacteristics"
          @setCalcCharacteristics="setCalcCharacteristics"
        />
        <div
          style="font-weight: bold; color: red"
          v-if="item.type_of_execution === 5 && !isTemplateGrommetsCorrect"
        >
          Ошибка в шаблоне
        </div>
        <div
          v-if="item.type_of_execution !== 4 && item.type_of_execution !== 5"
          class="list-options online-order-center-block-type-title"
        >
          <div>{{ item.name }}</div>
          <div class="online-order-center-block-group-radio">
            <v-select
              v-if="item.type_of_execution === 3"
              :options="item.value_options"
              :index="index"
              :id_characteristic="item.id"
              @selected="select"
            />
            <v-select-quantity
              v-if="item.type_of_execution === 103"
              :options="item.value_options"
              :index="index"
              :id_characteristic="item.id"
              @selectedEdition="select"
            />
            <v-select-quantity
              v-if="item.type_of_execution === 203"
              :options="item.value_options"
              :index="index"
              :id_characteristic="item.id"
              :size="true"
              @selectedEdition="select"
            />
            <v-btn-quantity
              v-if="item.type_of_execution === 101"
              :options="item.value_options"
              :index="index"
              :id_characteristic="item.id"
              @selectedEdition="select"
            />
            <v-btn-quantity
              v-if="item.type_of_execution === 201"
              :options="item.value_options"
              :index="index"
              :id_characteristic="item.id"
              :size="true"
              @selectedEdition="select"
            />
            <v-btn
              v-else-if="item.type_of_execution === 1"
              v-for="option in item.value_options"
              :key="option.id"
              :option_data="option"
              :id_characteristic="item.id"
              @selectOption="select"
              :index="index"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="main_block_right">
      <div class="block_circulation_and_cost">
        <div v-for="e in edition" :key="e.id" class="block_circulation">
          <div class="online-order-right-block-select-title">
            Выберите тираж или укажите свой:
          </div>
          <v-select-quantity
            v-if="e.type_of_execution === 103"
            :options="e.value_options"
            :index="e.index"
            :id_characteristic="e.id"
            @selectedEdition="select"
          />
        </div>
        <div class="block_cost">
          <div class="block_cost_tablet">
            <div class="block_cost_tablet_top">СТОИМОСТЬ ЗАКАЗА:</div>
            <div
              v-show="COST.calcStatus === true && isValid"
              class="block_cost_tablet_center costPreloader"
              :ref="'costRef'"
            ></div>
            <div
              v-show="COST.calcStatus === false"
              class="block_cost_tablet_center"
            >
              {{ COST.calcResult }}
            </div>
            <div class="block_cost_tablet_bottom">
              <p
                v-show="COST.weight && !COST_PRELOADER"
                style="color: rgb(140, 140, 140)"
              >
                Вес заказа: {{ Number(COST.weight) | numeral("0,0.") }} г
              </p>
              <!--              <p>готовность:</p>-->
              <!--              <p>понедельник 23 марта</p>-->
              <!--              <p>при запуске до 14 ч. сегодня</p>-->
            </div>
          </div>
          <div class="block_cost_button">
            <!--            <div @click="addToCart()" class="block_cost_button_btn">-->
            <!--              Добавить-->
            <!--            </div>-->
            <el-button
              size="medium"
              style="width: 272px"
              @click="addToCart()"
              :loading="addLoading"
              type="primary"
              plain
              :disabled="NUMBER_OF_REQUESTS !== 0 && COST_PRELOADER === true"
              >Добавить</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!--    <v-debug-->
    <!--      name="CHARACTERISTICS"-->
    <!--      :variable="CHARACTERISTICS"-->
    <!--      height="100vh"-->
    <!--      moving="Y"-->
    <!--    />-->
    <!--    <v-debug-->
    <!--      name="characteristics"-->
    <!--      :variable="characteristics"-->
    <!--      height="100vh"-->
    <!--    />-->
  </div>
</template>

<script>
import vBtn from "./v-btn";

import vSelect from "@/components/CostCalculation/v-select";
import vSelectQuantity from "@/components/CostCalculation/v-select-quantity";
import vBtnQuantity from "@/components/CostCalculation/v-btn-quantity";
import vEdgeProcessing from "@/components/CostCalculation/v-edge-processing";
// import Vue from "vue";
import VSelectionGrommets from "@/components/CostCalculation/v-selection-grommets";
// import VDebug from "@/components/debug/v-debug";

export default {
  name: "v-list",
  components: {
    // VDebug,
    VSelectionGrommets,
    vBtn,
    vSelect,
    vSelectQuantity,
    vBtnQuantity,
    vEdgeProcessing,
  },
  props: {
    characteristicsProps: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      title: "Список характеристик",
      type_id: "",
      type: [],
      characteristics: [],
      calcProcessingCharacteristics: [],
      calcGrommetsCharacteristics: [],
      show_component: false,
      isTemplateGrommetsCorrect: false,
      addLoading: false,
      prelo: {},
    };
  },
  computed: {
    VALIDATION_QUANTITY() {
      return this.$store.getters["CostCalculation/VALIDATION_QUANTITY"];
    },
    COST_PRELOADER() {
      return this.$store.getters["CostCalculation/COST_PRELOADER"];
    },
    NUMBER_OF_REQUESTS() {
      return this.$store.getters["CostCalculation/NUMBER_OF_REQUESTS"];
    },
    TEMPLATE() {
      return this.$store.getters["CostCalculation/TEMPLATE"];
    },
    USER_ID() {
      return this.$store.getters["CostCalculation/USER_ID"];
    },
    // CHARACTERISTICS() {
    //   return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    // },
    COST() {
      return this.$store.getters["CostCalculation/COST"];
    },
    TYPE() {
      return this.$store.getters["CostCalculation/TYPE"];
    },
    LIST_TYPES() {
      return this.$store.getters["CostCalculation/LIST_TYPES"];
    },
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    edition: function () {
      let TYPE = this.$store.getters["CostCalculation/TYPE"];
      return TYPE.filter(function (item, i) {
        if (item.ProdCount === true) {
          item.index = i;
          return item;
        }
      });
    },
    costPreloader() {
      return this.$store.getters["CostCalculation/COST_PRELOADER"];
    },
    NumberOfRequests() {
      return this.$store.getters["CostCalculation/NUMBER_OF_REQUESTS"];
    },
    isValid() {
      let is = true;
      if (this.VALIDATION_QUANTITY.indexOf !== -1) {
        const valid = Object.values(this.VALIDATION_QUANTITY);
        if (valid.indexOf(false) !== -1) {
          is = false;
        }
      }
      return is;
    },
  },
  methods: {
    GET_TEMPLATE_FROM_API() {
      return this.$store.dispatch("CostCalculation/GET_TEMPLATE_FROM_API");
    },
    GET_CHARACTERISTICS(value) {
      return this.$store.dispatch("CostCalculation/GET_CHARACTERISTICS", value);
    },
    GET_CALCULATE(add) {
      return this.$store.dispatch("CostCalculation/GET_CALCULATE", add);
    },
    SET_ID_TYPE(id) {
      return this.$store.dispatch("CostCalculation/SET_ID_TYPE", id);
    },
    GET_CALCULATE_TEMPLATE_CHARACTERISTICS(value) {
      return this.$store.dispatch(
        "CostCalculation/GET_CALCULATE_TEMPLATE_CHARACTERISTICS",
        value
      );
    },
    SET__TYPE(id) {
      return this.$store.dispatch("CostCalculation/SET__TYPE", id);
    },
    SET__EXEPTIONS(array) {
      return this.$store.dispatch("CostCalculation/SET__EXEPTIONS", array);
    },
    selectType(data) {
      this.type_id = data;
      this.forEachTypes(data);
      this.SET_ID_TYPE(data);
      this.set_type(this.type_id);
      this.setCharacteristics();
      this.GET_CALCULATE(false);
    },
    selectTypeSelected(type_id) {
      this.type_id = type_id;
      this.forEachTypes(type_id);
      this.SET_ID_TYPE(type_id);
      this.set_type(this.type_id);
      this.setCharacteristics();
      this.GET_CALCULATE(false);
    },
    forEachTypes(types_id) {
      let LIST_TYPES = this.$store.getters["CostCalculation/LIST_TYPES"];
      LIST_TYPES.forEach((item) => {
        if (item.id === types_id) {
          this.GET_CHARACTERISTICS(item.characteristics);
        }
      });
    },
    initTypes() {
      let LIST_TYPES = this.$store.getters["CostCalculation/LIST_TYPES"];
      let char = LIST_TYPES[0].characteristics;
      this.GET_CHARACTERISTICS(char);
      this.type_id = LIST_TYPES[0].id;
      this.SET_ID_TYPE(this.type_id);
      this.set_type(this.type_id);
      this.setCharacteristics();
    },
    select(selectedValue, id_value, index, id_characteristic) {
      let newChar = {
        id_characteristic: id_characteristic,
        sub_characteristics: [],
        value: selectedValue,
        id_value: id_value,
      };
      let payload = {
        characteristics: newChar,
        index: index,
      };
      this.$store.commit("CostCalculation/SET_VUE_CHARACTERISTICS", payload);
      this.characteristics =
        this.$store.getters["CostCalculation/CHARACTERISTICS"];
      this.GET_CALCULATE_TEMPLATE_CHARACTERISTICS(this.characteristics);
      this.GET_CALCULATE(false);
    },
    setCalcCharacteristics(
      characteristicIndex,
      calcCharacteristics,
      id_characteristic
    ) {
      let newChar = {
        id_characteristic: id_characteristic,
        sub_characteristics: calcCharacteristics,
        value: "",
        id_value: "",
      };
      let payload = {
        characteristics: newChar,
        index: characteristicIndex,
      };
      this.$store.commit("CostCalculation/SET_VUE_CHARACTERISTICS", payload);
      this.characteristics =
        this.$store.getters["CostCalculation/CHARACTERISTICS"];
      this.GET_CALCULATE_TEMPLATE_CHARACTERISTICS(this.characteristics);
      this.GET_CALCULATE(false);
    },
    setCharacteristics() {
      let TYPE = this.$store.getters["CostCalculation/TYPE"];
      TYPE.forEach((item, i) => {
        let sub_characteristics = [];
        switch (item.type_of_execution) {
          case 4:
            sub_characteristics = [
              {
                name: "BorderTop",
                optionIndex: null,
                idSideBtn: 0,
                idProBtn: 0,
                id_characteristic: "",
                value: "",
              },
              {
                name: "BorderRight",
                optionIndex: null,
                idSideBtn: 1,
                idProBtn: 0,
                id_characteristic: "",
                value: "",
              },
              {
                name: "BorderBottom",
                optionIndex: null,
                idSideBtn: 2,
                idProBtn: 0,
                id_characteristic: "",
                value: "",
              },
              {
                name: "BorderLeft",
                optionIndex: null,
                idSideBtn: 3,
                idProBtn: 0,
                id_characteristic: "",
                value: "",
              },
            ];
            sub_characteristics.forEach((char, id) => {
              let y = 0;
              let count = item.value_options.length;
              let set = true;
              do {
                if (
                  this.borderExists(item.value_options[y].borders, char.name)
                ) {
                  let charName = char.name;
                  item.value_options[y].borders.forEach((border) => {
                    switch (border.name) {
                      case charName:
                        sub_characteristics[id].optionIndex = y;
                        sub_characteristics[id].id_characteristic =
                          border["bid"];
                        sub_characteristics[id].value =
                          item.value_options[y]["values"][0]["value"];
                        set = false;
                        break;
                    }
                  });
                }
                y++;
                if (y === count) set = false;
              } while (set);
            });
            // sub_characteristics.sort((prev, next) => prev.age - next.age);
            var payload1 = {
              characteristics: {
                id_characteristic: item["id"],
                sub_characteristics: sub_characteristics,
                value: "",
                id_value: 0,
              },
              index: i,
            };
            this.$store.commit(
              "CostCalculation/SET_VUE_CHARACTERISTICS",
              payload1
            );
            this.characteristics =
              this.$store.getters["CostCalculation/CHARACTERISTICS"];
            // this.characteristics[i] = {
            //   id_characteristic: item["id"],
            //   sub_characteristics: sub_characteristics,
            //   value: "",
            //   id_value: 0,
            // };
            this.calcProcessingCharacteristics = sub_characteristics;
            break;
          case 5:
            this.isTemplateGrommetsCorrect =
              item.value_options.length === 3 &&
              this.isCorrect(item.value_options);
            if (this.isTemplateGrommetsCorrect) {
              sub_characteristics = [
                {
                  id: 0,
                  name: "Выбор люверса",
                  type: "CringleID",
                  values: {
                    id: 0,
                    name: "",
                    value: "",
                  },
                },
                {
                  id: 0,
                  name: "Стороны люверсов",
                  type: "CringleBorder",
                  fixed: false,
                  values: [
                    {
                      id: 0,
                      idSideBtn: 0,
                      name: "cBorderTop",
                      value: "cBorderTop",
                      blocked: false,
                      selected: false,
                    },
                    {
                      id: 0,
                      idSideBtn: 1,
                      name: "cBorderRight",
                      value: "cBorderRight",
                      blocked: false,
                      selected: false,
                    },
                    {
                      id: 0,
                      idSideBtn: 2,
                      name: "cBorderBottom",
                      value: "cBorderBottom",
                      blocked: false,
                      selected: false,
                    },
                    {
                      id: 0,
                      idSideBtn: 3,
                      name: "cBorderLeft",
                      value: "cBorderLeft",
                      blocked: false,
                      selected: false,
                    },
                  ],
                },
                {
                  id: 0,
                  name: "Шаг люверсов",
                  type: "CringleStep",
                  values: {
                    id: 0,
                    name: "",
                    value: "",
                  },
                },
              ];
              item.value_options.forEach((option) => {
                switch (option.type) {
                  case "CringleID":
                    option.optionIndex = 0;
                    sub_characteristics[0].optionIndex = 0;
                    sub_characteristics[0].id = option.id;
                    sub_characteristics[0].values.id = option.values[0].id;
                    sub_characteristics[0].values.name = option.values[0].name;
                    sub_characteristics[0].values.value =
                      option.values[0].value;
                    break;
                  case "CringleBorder":
                    option.optionIndex = 1;
                    sub_characteristics[1].optionIndex = 1;
                    sub_characteristics[1].id = option.id;
                    sub_characteristics[1].fixed = option.fixed;
                    // перебераем массив опций
                    sub_characteristics[1].values.forEach((char, id) => {
                      if (this.borderExists(option.values, char.name)) {
                        let charName = char.name;
                        option.values.forEach((border) => {
                          switch (border.name) {
                            case charName:
                              sub_characteristics[1].values[id].id = border.id;
                              sub_characteristics[1].values[id].selected = true;
                              break;
                          }
                        });
                      } else {
                        sub_characteristics[1].values[id].blocked = true;
                      }
                    });
                    break;
                  case "CringleStep":
                    option.optionIndex = 2;
                    sub_characteristics[2].optionIndex = 2;
                    sub_characteristics[2].id = option.id;
                    sub_characteristics[2].values.id = option.values[0].id;
                    sub_characteristics[2].values.name = option.values[0].name;
                    sub_characteristics[2].values.value =
                      option.values[0].value;
                }
              });
              let ITEM = JSON.parse(JSON.stringify(item));
              ITEM.value_options.sort(
                (prev, next) => prev.optionIndex - next.optionIndex
              ); // сортируем опции по индексу
              var payload2 = {
                characteristics: {
                  id_characteristic: ITEM["id"],
                  sub_characteristics: sub_characteristics,
                  value: "",
                  id_value: 0,
                },
                index: i,
              };
              this.$store.commit(
                "CostCalculation/SET_VUE_CHARACTERISTICS",
                payload2
              );
              this.characteristics =
                this.$store.getters["CostCalculation/CHARACTERISTICS"];
              // this.characteristics[i] = {
              //   id_characteristic: item["id"],
              //   sub_characteristics: sub_characteristics,
              //   value: "",
              //   id_value: 0,
              // };
              this.calcGrommetsCharacteristics = sub_characteristics;
            }
            break;
          default:
            var payload3 = {
              characteristics: {
                id_characteristic: item["id"],
                sub_characteristics: [],
                value: item.value_options[0]["value"],
                id_value: item.value_options[0]["id"],
              },
              index: i,
            };
            this.$store.commit(
              "CostCalculation/SET_VUE_CHARACTERISTICS",
              payload3
            );
            this.characteristics =
              this.$store.getters["CostCalculation/CHARACTERISTICS"];
          // this.characteristics[i] = {
          //   id_characteristic: item["id"],
          //   sub_characteristics: [],
          //   value: item.value_options[0]["value"],
          //   id_value: item.value_options[0]["id"],
          // };
        }
      });
      this.GET_CALCULATE_TEMPLATE_CHARACTERISTICS(this.characteristics);
    },
    borderExists(arr, name) {
      return arr.some(function (el) {
        return el.name === name;
      });
    },
    isCorrect(arr) {
      return arr.every(function (el) {
        return el.values.length >= 1;
      });
    },
    isActiveBtn(btnTypeId) {
      return this.type_id === btnTypeId;
    },
    foreachTypes(type_id) {
      return this.LIST_TYPES.filter(function (type) {
        if (type.id === type_id) {
          return type;
        }
      });
    },
    set_type(type_id) {
      let array = this.foreachTypes(type_id);
      this.SET__TYPE(array[0]);
      this.newExept(array[0].exceptions);
    },
    newExept(exetpions) {
      let array = [];
      exetpions.forEach((item) => {
        let obj = {};
        let val1 = item.list_of_values_for_exposure[0].id_value;
        let val2 = item.list_of_values[0].id_value;
        obj[val1] = val2;
        array.push(obj);
      });
      this.SET__EXEPTIONS(array);
    },
    addToCart() {
      if (this.isValid) {
        this.addLoading = true;
        this.openPreloader();
        this.$store.dispatch("Main/checkToken").then((response) => {
          if (
            response &&
            response.data.status &&
            response.data.status === 2000
          ) {
            this.GET_CALCULATE(true) //TODO долгое время запроса
              .then((responseCalculate) => {
                if (responseCalculate.data.status === 200) {
                  // let oId = this.$route.query.oid;
                  let oId = responseCalculate.data.oid;
                  this.$store
                    .dispatch("Main/customerOrderNewUpdate", oId) //TODO долгое время запроса
                    .then((responseOrderNewUpdate) => {
                      if (
                        responseOrderNewUpdate.data.status &&
                        responseOrderNewUpdate.data.status === 2000
                      ) {
                        this.$router
                          .push({
                            name: "order",
                            params: { oId },
                            // query: { status: "Created" },
                          })
                          .finally(() => {
                            this.$notify({
                              title: "Success",
                              message: "Заказ успешно добавлен!",
                              type: "success",
                              offset: 85,
                              duration: 6000,
                            });
                            this.addLoading = false;
                            this.closePreloader();
                          });
                      }
                      return responseOrderNewUpdate;
                    });
                }
              })
              .catch((error) => {
                if (error) {
                  this.$notify({
                    title: "Ошибка",
                    message: "Произошла ошибка, запрос не выполнен!",
                    type: "error",
                    offset: 85,
                    duration: 6000,
                  });
                  this.addLoading = false;
                  this.closePreloader();
                }
              });
          }
        });
      } else {
        this.$notify({
          title: "Ошибка",
          message: "Проверьте правильность введенных данных!",
          type: "error",
          offset: 85,
          duration: 6000,
        });
      }
    },
    prettify(num) {
      let n = String(num);
      return n.replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ");
    },
    openPreloader() {
      this.prelo = this.$loading({
        lock: true,
        background: "rgb(251, 251, 252)",
        text: "Добавляем заказ...",
        target: ".main_block_cost_calculation",
        spinner: "el-icon-loading",
      });
    },
    closePreloader() {
      this.prelo.close();
    },
  },
  mounted() {
    this.GET_TEMPLATE_FROM_API().then((response) => {
      if (response.data) {
        // console.log("Шаблон получен!!!");
        this.initTypes();
        this.GET_CALCULATE(false);
        this.show_component = true;
      }
    });
  },
  watch: {
    // CHARACTERISTICS(value) {
    //   this.characteristics = value;
    // },
    characteristicsProps: function (value) {
      this.characteristics = value;
    },
    costPreloader: function (value) {
      if (value === true && this.NUMBER_OF_REQUESTS > 0) {
        this.$refs.costRef.textContent = "Считаю...";
        const $ = window.$;
        $(".costPreloader").each(function () {
          let $this = $(this),
            // eslint-disable-next-line no-undef
            ticker = new Ticker($this, true).reset();
          $this.data("ticker", ticker);
        });
      }
      if (value === false && this.NUMBER_OF_REQUESTS === 0) {
        this.$refs.costRef.textContent =
          this.prettify(this.COST.cost) + " рублей.";
        const $ = window.$;
        $(".costPreloader").each(function () {
          let $this = $(this),
            // eslint-disable-next-line no-undef
            ticker = new Ticker($this, true).reset();
          $this.data("ticker", ticker);
        });
      }
    },
    NumberOfRequests: function (value) {
      if (value === 0 && this.COST_PRELOADER === false) {
        this.$refs.costRef.textContent =
          this.prettify(this.COST.cost) + " рублей.";
        const $ = window.$;
        $(".costPreloader").each(function () {
          let $this = $(this),
            // eslint-disable-next-line no-undef
            ticker = new Ticker($this, true).reset();
          $this.data("ticker", ticker);
        });
      }
    },
  },
};
</script>

<style lang="sass"></style>
