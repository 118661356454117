<template>
  <div class="v-select-quantity">
    <div class="v-select">
      <div
        class="selected select_values"
        :class="{ active: areOptionVisible, activeEl: activeEl === 'select' }"
        @click="multiplyActions('select')"
      >
        {{ selected }}
        <div
          :class="[definition !== '' ? 'icon-info' : 'icon-info-none']"
          style="margin-right: 12px"
        >
          <span class="tip">
            {{ definition }}
          </span>
        </div>
      </div>
      <ul class="options" v-show="areOptionVisible">
        <li
          v-for="option in computedListOption"
          :key="option.id + '_' + index"
          @click="selectedOption(option, index, id_characteristic)"
        >
          {{ option.name }}
        </li>
      </ul>
    </div>
    <div class="group-quantity">
      <div
        v-for="(myEdition, i) in computedMyEdition"
        :key="myEdition.id + '_' + i"
        @click="updateActiveEl('quantity')"
        class="div-quantity"
      >
        <span v-if="i === 1">x</span>
        <v-quantity
          :myEdition="myEdition"
          :maskMin="myEdition.maskMin"
          :maskMax="myEdition.maskMax"
          :maskStep="myEdition.maskStep"
          :maskLength="myEdition.maskLength"
          :index="index"
          :id_characteristic="id_characteristic"
          :sel="selectedInt"
          :size="size"
          :i="i"
          @selectedEdition="selectedMyQuantity"
          @selectedSize="selectedSize"
          :activeElement="activeEl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vQuantity from "./v-quantity";

export default {
  name: "v-select-quantity",
  components: {
    vQuantity,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    id_characteristic: {
      type: Number,
      default() {
        return 0;
      },
    },
    size: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      areOptionVisible: false,
      selected: "",
      definition: "",
      myQuantity: 0,
      activeEl: "select",
      sizeValue: "",
    };
  },
  computed: {
    computedListOption: function () {
      return this.options.filter(function (option) {
        if (!option.type_of_execution) {
          return option;
        }
      });
    },
    computedMyEdition: function () {
      return this.options.filter(function (option) {
        if (option.type_of_execution === 12) {
          if (option.mask) {
            let arrayMask = option.mask.split(",", 3);
            option.maskMin = arrayMask[0];
            option.maskMax = arrayMask[1];
            option.maskStep = arrayMask[2];
            option.maskLength = arrayMask[1].length;
          }
          return option;
        }
      });
    },
    selectedInt: function () {
      return String(this.myQuantity);
    },
  },
  methods: {
    selectedOption(option, index, id_characteristic) {
      this.selected = option.name;
      this.myQuantity = option.value;
      this.definition = option.definition;
      this.$emit(
        "selectedEdition",
        option.value,
        option.id,
        index,
        id_characteristic
      );
      this.areOptionVisible = false;
    },
    selectedMyQuantity(myEdition, id, index, id_characteristic, name) {
      this.selected = name;
      this.$emit("selectedEdition", myEdition, id, index, id_characteristic);
    },
    selectedSize(size_value, id, index, id_characteristic, i) {
      this.selected = "Мой размер";
      let arraySize = this.sizeValue.split("*", 2);
      arraySize[i] = size_value;
      this.sizeValue = arraySize.join("*");
      this.$emit(
        "selectedEdition",
        this.sizeValue,
        id,
        index,
        id_characteristic
      );
    },
    hideOptions() {
      this.areOptionVisible = false;
    },
    hideShow() {
      this.areOptionVisible = !this.areOptionVisible;
    },
    updateActiveEl(element) {
      this.activeEl = element;
    },
    multiplyActions(element) {
      this.hideShow();
      this.updateActiveEl(element);
    },
  },
  watch: {
    myQuantity: function (value) {
      this.sizeValue = value;
    },
  },
  mounted() {
    document.addEventListener("click", this.hideOptions.bind(this), true);
    this.selected = this.options[0].name;
    this.myQuantity = this.options[0].value;
    this.definition = this.options[0].definition;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideOptions);
  },
};
</script>

<style lang="sass"></style>
