<template>
  <div class="processing_button_block">
    <v-side-btn
      v-for="btn in this.buttons"
      :key="btn.idSideBtn"
      :cl="btn.cl"
      :name="btn.name"
      :idSideBtn="btn.idSideBtn"
      :bid="btn.bid"
      :btn_blocked="btn.btn_blocked"
      :optionIndex="btn.optionIndex"
      :characteristicIndex="characteristicIndex"
      @selectSideBtn="selectSideBtn"
      @selectAllSidesBtn="selectAllSidesBtn"
    ></v-side-btn>
  </div>
</template>

<script>
import VSideBtn from "@/components/CostCalculation/v-side-btn";

export default {
  name: "v-selecting-sides",
  components: { VSideBtn },
  props: {
    borders: {
      type: Array,
      default() {
        return [];
      },
    },
    optionIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      buttons: [
        {
          name: "BorderTop",
          cl: "pro_btn_top",
          idSideBtn: 0,
          bid: "",
          btn_blocked: true,
          optionIndex: 0,
        },
        {
          name: "BorderRight",
          cl: "pro_btn_right",
          idSideBtn: 1,
          bid: "",
          btn_blocked: true,
          optionIndex: 0,
        },
        {
          name: "BorderBottom",
          cl: "pro_btn_bottom",
          idSideBtn: 2,
          bid: "",
          btn_blocked: true,
          optionIndex: 0,
        },
        {
          name: "BorderLeft",
          cl: "pro_btn_left",
          idSideBtn: 3,
          bid: "",
          btn_blocked: true,
          optionIndex: 0,
        },
        {
          name: "BorderCenter",
          cl: "pro_btn_center",
          idSideBtn: 4,
          bid: "",
          btn_blocked: true,
          optionIndex: 0,
        },
      ],
    };
  },
  computed: {},
  methods: {
    creatingField() {
      this.borders.forEach((side) => {
        this.buttons.forEach((btn, i) => {
          if (btn.name === side.name) {
            this.buttons[i].bid = side.bid;
            this.buttons[i].btn_blocked = false;
          }
          this.buttons[i].optionIndex = this.optionIndex;
        });
      });
      if (this.borders.length === 4) {
        this.buttons[4].btn_blocked = false;
      }
    },
    selectSideBtn(infoSelectSideBtn) {
      this.$emit("selectSideBtn", infoSelectSideBtn);
    },
    selectAllSidesBtn() {
      let infoSidesBtn = this.buttons.filter((button) => {
        return button.idSideBtn !== 4;
      });
      this.$emit("selectAllSidesBtn", infoSidesBtn);
    },
  },
  watch: {},
  mounted() {
    this.creatingField();
  },
};
</script>

<style scoped></style>
