<template>
  <div class="v-quantity inputQuantity">
    <input
      v-model="computedMyQuantity"
      @keypress="isNumber($event)"
      @input="onInput($event, index, id_characteristic, myEdition.name)"
      :maxlength="maskLength"
      type="text"
      class="quantity__input inputQuantity"
      :class="{
        activeEl: activeElement === 'quantity',
        widthByMask: maskLength > 5,
      }"
      pattern="^[0-9]+$"
    />
    <div class="quantity__top" @click="myQuantityPlus"></div>
    <div class="quantity__bottom" @click="myQuantityMinus"></div>
    <span
      v-show="validation === false"
      :tooltip="computedStringTooltip"
      tooltip-position="top"
    ></span>
  </div>
</template>

<script>
export default {
  name: "v-quantity",
  props: {
    myEdition: {
      type: Object,
      default() {
        return {};
      },
    },
    maskMin: {
      type: String,
      default() {
        return "";
      },
    },
    maskMax: {
      type: String,
      default() {
        return "";
      },
    },
    maskStep: {
      type: String,
      default() {
        return "";
      },
    },
    maskLength: {
      type: Number,
      default() {
        return 0;
      },
    },
    sel: {
      type: String,
      default() {
        return "0";
      },
    },
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    id_characteristic: {
      type: Number,
      default() {
        return 0;
      },
    },
    activeElement: {
      type: String,
      default() {
        return "";
      },
    },
    size: {
      type: Boolean,
      default() {
        return false;
      },
    },
    i: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      myQuantity: "",
      activeEl: "",
      validation: true,
    };
  },
  computed: {
    computedMyQuantity: {
      get() {
        return this.myQuantity;
      },
      set(value) {
        this.myQuantity = value;
      },
    },
    computedStringTooltip: function () {
      return "Мин: " + this.maskMin + " Макс: " + this.maskMax;
    },
  },
  methods: {
    SET_VALIDATION_QUANTITY(value) {
      return this.$store.dispatch(
        "CostCalculation/SET_VALIDATION_QUANTITY",
        value
      );
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onInput(e, index, id_characteristic, name) {
      if (this.myQuantity === "") {
        this.myQuantity = this.maskMin;
        this.validation = true;
      } else if (parseInt(this.maskMax) < parseInt(e.target.value)) {
        this.myQuantity = this.maskMax;
        this.validation = true;
      } else if (parseInt(this.maskMin) > parseInt(this.myQuantity)) {
        this.validation = false;
      } else {
        this.myQuantity = e.target.value;
        this.validation = true;
      }

      if (this.validation === true) {
        if (this.size === false) {
          this.$emit(
            "selectedEdition",
            String(this.myQuantity),
            this.myEdition.id,
            index,
            id_characteristic,
            name
          );
        } else {
          this.$emit(
            "selectedSize",
            String(this.myQuantity),
            this.myEdition.id,
            index,
            id_characteristic,
            this.i
          );
        }
      }
      this.setValidation();
    },
    roundTo(num, step) {
      return Math.round(num / step) * step;
    },
    myQuantityPlus() {
      if (this.myQuantity === "") return;
      let round = this.roundTo(
        parseInt(this.myQuantity),
        parseInt(this.maskStep)
      );
      let total = round + parseInt(this.maskStep);
      if (total <= this.maskMax && total >= this.maskMin) {
        this.myQuantity = total;
      } else if (total < this.maskMin) {
        this.myQuantity = this.maskMin;
      } else {
        this.myQuantity = this.maskMax;
      }
      this.validation = true;
      this.methodEmit();
    },
    myQuantityMinus() {
      if (this.myQuantity === "") return;
      let round = this.roundTo(
        parseInt(this.myQuantity),
        parseInt(this.maskStep)
      );
      let total = round - parseInt(this.maskStep);
      if (total >= this.maskMin && total <= this.maskMax) {
        this.myQuantity = total;
      } else if (total > this.maskMax) {
        this.myQuantity = this.maskMax;
      } else {
        this.myQuantity = this.maskMin;
      }
      this.validation = true;
      this.methodEmit();
    },
    methodEmit() {
      if (this.validation === true) {
        if (this.size === false) {
          this.$emit(
            "selectedEdition",
            String(this.myQuantity),
            this.myEdition.id,
            this.index,
            this.id_characteristic,
            this.myEdition.name
          );
        } else {
          this.$emit(
            "selectedSize",
            String(this.myQuantity),
            this.myEdition.id,
            this.index,
            this.id_characteristic,
            this.i
          );
        }
      }
      this.setValidation();
    },
    setValidation() {
      let obj = {
        key: this.myEdition.id,
        value: this.validation,
      };
      this.SET_VALIDATION_QUANTITY(obj);
    },
  },
  watch: {
    sel: function (value) {
      if (this.size === true) {
        let arraySize = value.split("*", 2);
        this.myQuantity = String(arraySize[this.i]);
      } else this.myQuantity = value;
    },
    myQuantity: function (value) {
      if (parseInt(value) < parseInt(this.maskMin)) {
        this.validation = false;
      } else this.validation = true;
    },
    activeEl: function (value) {
      this.activeElement = value;
    },
  },
  mounted() {
    this.setValidation();
  },
};
</script>

<style lang="sass"></style>
