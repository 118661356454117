<template>
  <div :class="cl"></div>
</template>

<script>
export default {
  name: "v-gromm-corner",
  props: {
    cl: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
