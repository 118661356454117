<template>
  <div class="selection_of_grommets_right">
    <div class="selection_of_grommets_right_block_name">
      <p class="selection_of_grommets_name">{{ option.name }}</p>
    </div>
    <div class="selection_of_grommets_right_block_button">
      <v-gromm-btn
        v-for="grommBtn in option.values"
        :key="grommBtn.id"
        :grommBtn="grommBtn"
        :idGrommBtn="grommBtn.id"
        :characteristicIndex="characteristicIndex"
        :optionIndex="option.optionIndex"
        @selectGrommBtn="selectGrommBtn"
      />
    </div>
  </div>
</template>

<script>
import VGrommBtn from "@/components/CostCalculation/v-gromm-btn";

export default {
  name: "v-grommets-steps",
  components: { VGrommBtn },
  props: {
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  methods: {
    selectGrommBtn(infoSelectGrommBtn) {
      this.$emit("selectGrommBtn", infoSelectGrommBtn);
    },
  },
};
</script>

<style scoped></style>
