<template>
  <div
    class="main_block_cost_calculation"
    v-loading="PRELOADER"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgb(251, 251, 252)"
    element-loading-text="Загрузка..."
  >
    <div class="header_cost_calculation">
      <div class="header_cost_calculation_left">
        <img
          id="template_img"
          src="img/logo-form.svg"
          alt=""
          class="header_cost_calculation_left_img"
        />
      </div>
      <div class="header_cost_calculation_center">
        <p
          id="template_caption"
          class="header_cost_calculation_center_title"
        ></p>
        <p
          id="template_name"
          class="header_cost_calculation_center_subtitle"
        ></p>
      </div>
      <div class="header_cost_calculation_right">
        <router-link
          to="/new_order/list_products"
          v-slot="{ href, route, navigate }"
        >
          <el-button
            size="medium"
            class=""
            :href="href"
            @click="navigate"
            icon="el-icon-menu"
            plain
            >В меню</el-button
          >
        </router-link>
      </div>
    </div>
    <div>
      <v-main v-show="PRELOADER_DISPLAY_NONE"></v-main>
      <div class="span_hidden"></div>
    </div>
  </div>
</template>

<script>
import vMain from "@/components/CostCalculation/v-main";
export default {
  name: "NewOrderCostCalculation",
  data() {
    return {};
  },
  components: {
    vMain,
  },
  computed: {
    PRELOADER_DISPLAY_NONE() {
      return this.$store.getters["CostCalculation/PRELOADER_DISPLAY_NONE"];
    },
    PRELOADER() {
      return this.$store.getters["CostCalculation/PRELOADER"];
    },
  },
  methods: {},
  watch: {},
  created() {},
  destroyed() {},
  mounted() {},
};
</script>

<style lang="scss">
.el-main {
  //overflow: unset !important;
}
.main_block_cost_calculation {
  display: block;
  width: 100%;
  min-height: calc(100vh - 120px);
  padding: 0 4px;
}
</style>
