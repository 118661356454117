<template>
  <div
    :class="[
      cl,
      btn_blocked || blockedBtnAll ? 'btn_blocked' : '',
      isActiveBtn ? isActiveCurrentCssClass : '',
    ]"
    @click="selectSideBtn"
  ></div>
</template>

<script>
export default {
  name: "v-side-btn",
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    cl: {
      type: String,
      default() {
        return "";
      },
    },
    idSideBtn: {
      type: Number,
      default() {
        return 0;
      },
    },
    bid: {
      type: String,
      default() {
        return "";
      },
    },
    optionIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    btn_blocked: {
      type: Boolean,
      default() {
        return true;
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    isActiveBtn() {
      if (this.idSideBtn === 4) {
        let currentSidesInOption = this.CHARACTERISTICS[
          this.characteristicIndex
        ].sub_characteristics.filter((char) => {
          return char.optionIndex === this.optionIndex;
        });
        return currentSidesInOption.length === 4;
      } else if (
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[
          this.idSideBtn
        ].optionIndex === this.optionIndex
      ) {
        // this.$emit("activeEl", this.idSideBtn, this.optionIndex);
        return true;
      }
      return false;
    },
    blockedBtnAll() {
      if (this.idSideBtn === 4) {
        let currentSidesInOption = this.CHARACTERISTICS[
          this.characteristicIndex
        ].sub_characteristics.filter((char) => {
          return char.optionIndex === this.optionIndex;
        });
        return currentSidesInOption.length === 4;
      }
      return false;
    },
    isActiveCurrentCssClass() {
      if (this.idSideBtn === 4) return "btn_blocked";
      else return "btn_fixed";
    },
  },
  methods: {
    selectSideBtn() {
      if (this.idSideBtn === 4) {
        if (this.idSideBtn === 4) this.$emit("selectAllSidesBtn");
      } else {
        let infoSelectSideBtn = {
          optionIndex: this.optionIndex,
          idSideBtn: this.idSideBtn,
          bid: this.bid,
        };
        this.$emit("selectSideBtn", infoSelectSideBtn);
      }
    },
  },
};
</script>

<style scoped></style>
