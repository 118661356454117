<template>
  <div class="main_block_left_list_products">
    <ul
      v-for="type in LIST_TYPES"
      :key="type.id"
      class="v-list-types list_products_ul"
    >
      <li
        class="list_products_ul_li"
        :class="{ product_active: TYPE_ID === type.id }"
        @click="selectType(type.id)"
      >
        {{ type.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "v-list-types",
  data() {
    return {
      type_id: "",
      characteristics: [],
    };
  },
  computed: {
    LIST_TYPES() {
      return this.$store.getters["CostCalculation/LIST_TYPES"];
    },
    TYPE_ID() {
      return this.$store.getters["CostCalculation/TYPE_ID"];
    },
    TYPE() {
      return this.$store.getters["CostCalculation/TYPE"];
    },
  },
  methods: {
    SET__TYPE(id) {
      return this.$store.dispatch("CostCalculation/SET__TYPE", id);
    },
    SET_ID_TYPE(id) {
      return this.$store.dispatch("CostCalculation/SET_ID_TYPE", id);
    },
    GET_CHARACTERISTICS(value) {
      return this.$store.dispatch("CostCalculation/GET_CHARACTERISTICS", value);
    },
    GET_CALCULATE(add) {
      return this.$store.dispatch("CostCalculation/GET_CALCULATE", add);
    },
    GET_CALCULATE_TEMPLATE_CHARACTERISTICS(value) {
      return this.$store.dispatch(
        "CostCalculation/GET_CALCULATE_TEMPLATE_CHARACTERISTICS",
        value
      );
    },
    SET__EXEPTIONS(array) {
      return this.$store.dispatch("CostCalculation/SET__EXEPTIONS", array);
    },
    selectType(id) {
      this.type_id = id;
      this.forEachTypes(id);
      this.SET_ID_TYPE(id);
      this.set_type(this.type_id);
      this.setCharacteristics();
      this.GET_CALCULATE(false);
      this.$emit("sendCharacteristics", this.characteristics);
    },
    forEachTypes(types_id) {
      this.LIST_TYPES.forEach((item) => {
        if (item.id === types_id) {
          this.GET_CHARACTERISTICS(item.characteristics);
        }
      });
    },
    foreachTypes(type_id) {
      return this.LIST_TYPES.filter(function (type) {
        if (type.id === type_id) {
          return type;
        }
      });
    },
    setCharacteristics() {
      this.TYPE.forEach((item, i) => {
        this.characteristics[i] = {
          id_characteristic: item["id"],
          sub_characteristics: [],
          value: item.value_options[0]["value"],
          id_value: item.value_options[0]["id"],
        };
      });
      this.GET_CALCULATE_TEMPLATE_CHARACTERISTICS(this.characteristics);
    },
    set_type(type_id) {
      let array = this.foreachTypes(type_id);
      this.SET__TYPE(array[0]);
      this.newExept(array[0].exceptions);
    },
    newExept(exetpions) {
      let array = [];
      exetpions.forEach((item) => {
        let obj = {};
        let val1 = item.list_of_values_for_exposure[0].id_value;
        let val2 = item.list_of_values[0].id_value;
        obj[val1] = val2;
        array.push(obj);
      });
      this.SET__EXEPTIONS(array);
    },
  },
};
</script>

<style scoped></style>
