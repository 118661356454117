<template>
  <div class="selection_of_grommets_center">
    <div class="selection_of_grommets_center_block_name">
      <p class="selection_of_grommets_name">
        {{ option.name }}
      </p>
    </div>
    <div class="selection_of_grommets_center_block_button">
      <v-gromm-side-btn
        v-for="btn in this.buttons"
        :key="btn.idSideBtn"
        :cl="btn.cl"
        :name="btn.name"
        :idSideBtn="btn.idSideBtn"
        :id="btn.id"
        :btn_blocked="btn.btn_blocked"
        :fixed="option.fixed"
        :optionIndex="btn.optionIndex"
        :characteristicIndex="characteristicIndex"
        @selectGrommSideBtn="selectGrommSideBtn"
        @selectGrommAllSidesBtn="selectGrommAllSidesBtn"
      ></v-gromm-side-btn>
      <v-gromm-corner
        v-show="activeCorners"
        v-for="cl in corners_class"
        :key="cl"
        :cl="cl"
      />
    </div>
  </div>
</template>

<script>
import VGrommSideBtn from "@/components/CostCalculation/v-gromm-side-btn";
import VGrommCorner from "@/components/CostCalculation/v-gromm-corner";
export default {
  name: "v-grommets-sides",
  components: {
    VGrommCorner,
    VGrommSideBtn,
  },
  props: {
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      buttons: [
        {
          name: "cBorderTop",
          cl: "gromm_btn_top",
          idSideBtn: 0,
          id: 0,
          btn_blocked: true,
          optionIndex: 0,
        },
        {
          name: "cBorderRight",
          cl: "gromm_btn_right",
          idSideBtn: 1,
          id: 0,
          btn_blocked: true,
          optionIndex: 0,
        },
        {
          name: "cBorderBottom",
          cl: "gromm_btn_bottom",
          idSideBtn: 2,
          id: 0,
          btn_blocked: true,
          optionIndex: 0,
        },
        {
          name: "cBorderLeft",
          cl: "gromm_btn_left",
          idSideBtn: 3,
          id: 0,
          btn_blocked: true,
          optionIndex: 0,
        },
        {
          name: "cBorderCenter",
          cl: "gromm_btn_center",
          idSideBtn: 4,
          id: 0,
          btn_blocked: true,
          optionIndex: 0,
        },
      ],
      corners_class: [
        "upper_left_corner",
        "upper_right_corner",
        "lower_left_corner",
        "lower_right_corner",
      ],
    };
  },
  computed: {
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    activeCorners() {
      return (
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[2]
          .values.value === "-1" &&
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[0]
          .values.value !== "0"
      );
    },
  },
  methods: {
    creatingField() {
      this.option.values.forEach((side) => {
        this.buttons.forEach((btn, i) => {
          if (btn.name === side.name) {
            this.buttons[i].id = side.id;
            this.buttons[i].btn_blocked = false;
          }
          this.buttons[i].optionIndex = this.option.optionIndex;
        });
      });
      if (this.option.values.length === 4) {
        this.buttons[4].btn_blocked = false;
      }
    },
    selectGrommSideBtn(infoSelectGrommSideBtn) {
      this.$emit("selectGrommSideBtn", infoSelectGrommSideBtn);
    },
    selectGrommAllSidesBtn(selected) {
      this.$emit("selectGrommAllSidesBtn", selected);
    },
  },
  watch: {},
  mounted() {
    this.creatingField();
  },
};
</script>

<style scoped></style>
