<template>
  <div
    class="pro_btn"
    :class="[
      grommBtn.definition === '' ? 'pr16' : '',
      inactiveBlock ? 'pro_btn_inactive' : '',
      isActiveBtn ? 'activeEl' : '',
    ]"
    @click="selectGrommBtn"
  >
    <div class="proBtnName">{{ grommBtn.name }}</div>
    <div v-if="grommBtn.definition !== ''" class="icon-info">
      <span class="tip">
        {{ grommBtn.definition }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-gromm-btn",
  props: {
    grommBtn: {
      type: Object,
      default() {
        return {};
      },
    },
    optionIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    idGrommBtn: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    isActiveBtn() {
      let is_active = false;
      this.CHARACTERISTICS[
        this.characteristicIndex
      ].sub_characteristics.forEach((char) => {
        if (
          char.optionIndex === this.optionIndex &&
          char.values.id === this.idGrommBtn
        ) {
          is_active = true;
        }
      });
      if (
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[0]
          .values.value === "0" &&
        this.optionIndex === 2
      )
        return false;
      return is_active;
    },
    inactiveBlock() {
      return (
        this.CHARACTERISTICS[this.characteristicIndex].sub_characteristics[0]
          .values.value === "0" && this.optionIndex === 2
      );
    },
  },
  methods: {
    selectGrommBtn() {
      let infoSelectGrommBtn = {
        values: {
          id: this.grommBtn.id,
          name: this.grommBtn.name,
          value: this.grommBtn.value,
        },
        optionIndex: this.optionIndex,
      };
      this.$emit("selectGrommBtn", infoSelectGrommBtn);
    },
  },
};
</script>

<style scoped></style>
