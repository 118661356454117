<template>
  <div class="list_products_block">
    <div class="list_products_block_header">
      <div class="block_header_left">Выберите продукт для расчёта</div>
      <div class="block_header_right">
        <el-input
          placeholder="Введите название продукта для фильтрации"
          prefix-icon="el-icon-search"
          v-model="search"
          size="medium"
        >
        </el-input>
      </div>
    </div>
    <div v-show="filteredList.length > 0">
      <div class="list_products_block_main">
        <div
          v-for="(item, i) in filteredList"
          :key="i"
          class="product_card"
          :title="item.desc"
        >
          <figure
            class="product_card_figure"
            @click="SelectCurrentProduct(item.id)"
          >
            <el-image
              :src="item.image"
              class="product_card_figure_img"
              alt=""
              width="150"
              height="150"
            >
              <div slot="placeholder" class="image-slot">
                Загрузка<span class="dot">...</span>
              </div>
            </el-image>
            <figcaption class="product_card_figure_name">
              {{ item.name }}
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
    <div
      v-show="filteredList.length === 0"
      v-loading="computedProducts.length === 0"
      element-loading-text="Загрузка продуктов..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgb(251, 251, 252)"
      style="
        min-height: calc(100vh - 180px);
        padding-left: 6px;
        padding-top: 6px;
      "
    >
      <div class="new_order_list_products">
        <div
          class="block_products"
          v-for="(product, index) in computedProducts"
          :key="index"
        >
          <div class="list_line_products">
            <div
              v-for="(item, i) in product"
              :key="i"
              class="product_card"
              @click="show(item.sub, index, i, item.id)"
              :class="[
                product_index === i && sub_products_index === index
                  ? 'product_card_active'
                  : '',
              ]"
              :title="item.desc"
            >
              <figure class="product_card_figure">
                <el-image
                  :src="item.image"
                  class="product_card_figure_img"
                  alt=""
                  width="150"
                  height="150"
                >
                  <div slot="placeholder" class="image-slot">
                    Загрузка<span class="dot">...</span>
                  </div>
                </el-image>
                <figcaption class="product_card_figure_name">
                  {{ item.name }}
                </figcaption>
              </figure>
            </div>
          </div>
          <div
            class="list_line_sub_products"
            v-show="sub_products_index === index && show_sub_products === true"
          >
            <div
              class="sub_product_card"
              v-for="(prod, ind) in sub_products"
              :key="ind"
              :title="prod.desc"
            >
              <figure
                class="sub_product_card_figure"
                @click="SelectCurrentProduct(prod.id)"
              >
                <img
                  :src="prod.image"
                  class="sub_product_card_figure_img"
                  alt=""
                  width="96"
                  height="96"
                />
                <figcaption class="sub_product_card_figure_name">
                  {{ prod.name }}
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="imgHidden" style="position: fixed; left: 9999px; opacity: 0"></div>
  </div>
</template>

<script>
export default {
  name: "NewOrderListProducts",
  data() {
    return {
      search: "",
      products: [],
      window: {
        width: 0,
        height: 0,
      },
      sub_products: [],
      show_sub_products: false,
      product_index: null,
      sub_products_index: null,
      computedProducts: [],
    };
  },
  computed: {
    filteredList: function () {
      let search = this.search;
      if (search !== "") {
        return this.fullList.filter(function (elem) {
          return elem.name.toLowerCase().indexOf(search) !== -1;
        });
      } else {
        return [];
      }
    },
    fullList() {
      let array = [];
      this.Templates.forEach((item) => {
        if (item.sub.length > 0) {
          array = array.concat(item.sub);
        } else {
          array.push(item);
        }
      });
      return array;
    },
    Templates() {
      return this.$store.getters["NewOrder/Templates"];
    },
    newOrderOid() {
      return this.$store.getters["NewOrder/newOrderOid"];
    },
  },
  methods: {
    SelectCurrentProduct(templateId) {
      // const oid = this.newOrderOid;
      this.$router.push({
        name: "cost_calculation",
        // query: { templateId: templateId, oid: oid },
        query: { templateId: templateId },
      });
    },
    handleResize() {
      this.window.width = window.innerWidth - 60;
      this.window.height = window.innerHeight;
    },
    number_products() {
      // console.log(Math.floor(this.window.width / 204));
      return Math.floor(this.window.width / 224);
    },
    show(sub_products, index, i, id) {
      this.sub_products = [];
      if (this.sub_products_index === index && this.product_index === i) {
        this.show_sub_products = !this.show_sub_products;
        this.sub_products = sub_products;
      } else {
        this.sub_products = sub_products;
        this.sub_products_index = index;
        this.product_index = i;
        this.show_sub_products = true;
      }
      if (sub_products.length === 0) {
        this.SelectCurrentProduct(id);
      }
    },
    getProducts() {
      let products = this.$store.getters["NewOrder/Templates"];
      if (products.length > 0) {
        let currentArray = products.slice(0);
        let array = [];
        let n = 0;
        while (currentArray.length > 0) {
          let qq = currentArray.splice(0, this.number_products());
          array[n] = qq;
          n++;
        }
        this.computedProducts = array;
      }
      products.forEach((item) => {
        if (item.sub.length > 0) {
          item.sub.forEach((sub) => {
            this.setImg(sub.image);
          });
        }
      });
    },
    setImg(src) {
      const imgHidden = document.getElementById("imgHidden");
      const img = document.createElement("img");
      img.src = src;
      imgHidden.appendChild(img);
    },
  },
  watch: {},
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$store.dispatch("NewOrder/customerTemplates").then((response) => {
      if (response.data.status && response.data.status === 2000) {
        this.getProducts();
      }
    });
  },
};
</script>

<style lang="sass">
@import "../../assets/sass/list_products"
</style>
