<template>
  <div
    class="pro_btn"
    :class="[
      proBtn.definition === '' ? 'pr16' : '',
      inactiveBlock ? 'pro_btn_inactive' : '',
      isActiveBtn ? 'activeEl' : '',
    ]"
    @click="selectProBtn"
  >
    <div class="proBtnName">{{ proBtn.name }}</div>
    <div v-if="proBtn.definition !== ''" class="icon-info">
      <span class="tip">
        {{ proBtn.definition }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-processing-btn",
  props: {
    proBtn: {
      type: Object,
      default() {
        return {};
      },
    },
    computedCurrentSidesBtn: {
      type: Array,
      default() {
        return [];
      },
    },
    optionIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    characteristicIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    idProBtn: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    CHARACTERISTICS() {
      return this.$store.getters["CostCalculation/CHARACTERISTICS"];
    },
    isActiveBtn() {
      let is_active = false;
      this.CHARACTERISTICS[
        this.characteristicIndex
      ].sub_characteristics.forEach((char) => {
        if (
          char.optionIndex === this.optionIndex &&
          char.idProBtn === this.idProBtn
        ) {
          is_active = true;
        }
      });
      return is_active;
    },
    inactiveBlock() {
      return this.computedCurrentSidesBtn.length < 1;
    },
  },
  methods: {
    selectProBtn() {
      let infoSelectProBtn = {
        idProBtn: this.idProBtn,
        valueProBtn: this.proBtn.value,
      };
      this.$emit("selectProBtn", infoSelectProBtn);
    },
  },
};
</script>

<style scoped></style>
