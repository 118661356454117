<template>
  <div class="v-select" :style="{ width: max_width + width_select + 'px' }">
    <div
      class="selected select_values activeEl"
      :class="{ active: areOptionVisible }"
      @click="hideShow"
    >
      {{ selected }}
      <div
        :class="[definition !== '' ? 'icon-info' : 'icon-info-none']"
        style="margin-right: 12px"
      >
        <span class="tip">
          {{ definition }}
        </span>
      </div>
    </div>
    <ul class="options" v-show="areOptionVisible">
      <li
        v-for="option in options"
        :key="option.id"
        @click="selectedOption(option, index, id_characteristic)"
        :class="{
          select_not_active: isExeptions(option.id),
        }"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "v-select",
  props: {
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    id_characteristic: {
      type: Number,
      default() {
        return 0;
      },
    },
    type: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      areOptionVisible: false,
      selected: "",
      definition: "",
      max_length: 0,
      max_width: 0,
      string_max_width: "",
      dif_max_width: "",
      width_select: 95,
    };
  },
  computed: {
    list_of_selected_options() {
      return this.$store.getters["CostCalculation/list_of_selected_options"];
    },
    EXEPTIONS() {
      return this.$store.getters["CostCalculation/EXEPTIONS"];
    },
  },
  methods: {
    isExeptions(id) {
      let is = false;
      this.EXEPTIONS.forEach((item) => {
        if (
          id in item &&
          this.list_of_selected_options.indexOf(item[id]) !== -1
        ) {
          is = true;
        }
      });
      return is;
    },
    selectedOption(option, index, id_characteristic) {
      this.selected = option.name;
      this.definition = option.definition;
      if (this.type === false) {
        this.$emit(
          "selected",
          option.value,
          option.id,
          index,
          id_characteristic
        );
      }
      if (this.type === true) {
        this.$emit("selected", option.id);
      }
      this.areOptionVisible = false;
    },
    hideOptions() {
      this.areOptionVisible = false;
    },
    hideShow() {
      this.areOptionVisible = !this.areOptionVisible;
    },
    getMaxWidth() {
      this.options.forEach((item) => {
        if (item.name.length > this.max_length) {
          this.max_length = item.name.length;
          this.string_max_width = item.name;
          this.dif_max_width = item.definition;
        }
      });
      const $ = window.$;
      let wraped = "<span>" + this.string_max_width + "</span>";
      $("div.span_hidden").html(wraped);
      this.max_width = $("div.span_hidden").find("span:first-child").width();
      // $("div.span_hidden").remove();
    },
  },
  mounted() {
    this.getMaxWidth();

    document.addEventListener("click", this.hideOptions.bind(this), true);
    this.selected = this.options[0].name;
    this.definition = this.options[0].definition;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideOptions);
  },
};
</script>

<style lang="sass"></style>
